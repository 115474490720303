<script>
import BVForm from '@/components/BVForm/BVForm'
import ItemList from '@/components/ItemList/ItemList'
import Swal from 'sweetalert2'

export default {
    name: 'FormularioDePostulacion',
    components: {
        BVForm,
        ItemList
    },
    data() {
        return {
            vacante: null,
            datosPersonales: null,
            aspiranteId: null,
        }
    },
    async mounted() {
        this.getVacante()
        this.datosPersonales = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaPostulacionDatosPersonales')))

        this.$bvModal.show('modal-postulacion-alternativa')
    },
    computed: {
        vacanteId() {
            return this.$route.params.vacanteId
        }
    },
    methods: {
        async getVacante() {
            const response = await this.$store.getters.fetchGet({ path: `vacante/${this.vacanteId}` })
            this.vacante = await response.json()
            console.log(this.vacante);
        },
        getDepartamentosByPaisId(paisId) {
            return this.$store.getters.getSourceData('departamentos').filter(item => item.paisId == paisId)
        },
        getMunicipiosByDepartamentoId(departamentoId) {
            return this.$store.getters.getSourceData('municipios').filter(item => item.departamentoPaisId == departamentoId)
        },
        watcherFormularioPostulacion({ name, value, idx }) {

            if (name == 'paisId') {
                this.datosPersonales[idx + 1].value = null
                this.datosPersonales[idx + 2].value = null
                this.datosPersonales[idx + 1].options = this.getDepartamentosByPaisId(value)
            }
            else if (name == 'departamentoId') {
                this.datosPersonales[idx + 1].value = null
                this.datosPersonales[idx + 1].options = this.getMunicipiosByDepartamentoId(value)
            }

        },
        async sendFormularioDePostulacion(self) {

            const data = {
                ...self.data,
                vacanteId: this.vacanteId,
            }
            self.loader = true
            const response = await this.$store.getters.fetchPost({ path: 'Postulacion/DatosPersonales', data })
            const aspirante = await response.json()
            self.loader = false

            this.$router.push({ name: "FormularioSubDePostulacion", params: { vacanteId: this.vacanteId, aspiranteId: aspirante.id } })
        },
        openModal(modalId) {
            this.$bvModal.show(modalId);
        },
        aplicarTalentoInterno(self) {
            self.loader = true
        
            this.$store.getters.fetchPost({ path: `Postulacion/AplicarConIdentificacionDeTalentoInterno/${self.data.numeroIdentidicacion}/${this.vacanteId}/${this.vacante.cargo.area.empresa.id}` })
            .then( response => {
                if( !response.ok )
                {
                    return response.json().then( error => {
                        throw new Error(error.message)
                    })
                }
                return response.json()
            })
            .then( result => {
                this.$router.push({ name: "FormularioSubDePostulacion", params: { vacanteId: this.vacanteId, aspiranteId: result.id } })
            })
            .catch( error => {
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                })
            })
            .finally(() => {
                self.loader = false
            })
        },
        async aplicarIdentificacionExistente(self) {
            self.loader = true
            
            this.$store.getters.fetchPost({ path: `Postulacion/AplicarConIdentificacionExistente/${self.data.numeroIdentidicacion}/${this.vacanteId}` })
            .then( response => {
                if( !response.ok )
                {
                    return response.json().then( error => {
                        throw new Error(error.message)
                    })
                }
                return response.json()
            })
            .then( result => {
                this.$router.push({ name: "FormularioSubDePostulacion", params: { vacanteId: this.vacanteId, aspiranteId: result.id } })
            })
            .catch( error => {
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                })
            })
            .finally(() => {
                self.loader = false
            })



        }
    }
}

</script>


<template>
    <div class="container-fluid">
        <card class="px-3">
            <section class="mt-2 mb-4">
                <h3>
                    Información de la vacante
                </h3>
                <div class="row mt-3" v-if="vacante">
                    <div class="col">
                        <ItemList title="Nombre de la empresa" :value="vacante.cargo.area.empresa.nombre" />
                        <ItemList title="Nombre de la vacante" :value="vacante.cargo.nombre" />
                    </div>
                    <div class="col">
                        <ItemList title="Fecha de cierre" :value="vacante.fechaDeCierre" />
                        <div class="mt-3">

                            <div @click="openModal('modal-postulacion-alternativa')" class="btn btn-primary">
                                Otros mecanismos de postulación
                                <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section>
                <BVForm title="Formulario de postulación" :schema="datosPersonales"
                    :callBackSubmit="sendFormularioDePostulacion" :observer="['paisId', 'departamentoId']"
                    @watcher="watcherFormularioPostulacion">
                    <template #text-submit>
                        Enviar
                    </template>
                </BVForm>
            </section>

        </card>

        <b-modal id="modal-postulacion-alternativa" class="modal-sq" size="lg" hide-footer hide-header>
            <template #default="{ close }">
                <div class="px-3 py-2">
                    <div class="d-flex justify-content-between mb-3" style="width: 100%;">
                        <p class="mb-0 h4"><strong>Mecanismos de postulación</strong></p>
                        <div @click="close()" class="btn btn-danger btn-sm btn-pm">
                            <i class="fas fa-times"></i>
                        </div>
                    </div>
                    <div>
                        <p class="text-justify">
                            A continuación, se dispone de un formulario de solicitud que requiere el diligenciamiento de los
                            campos específicos.
                            Asimismo, se contemplan dos alternativas adicionales para la postulación.
                        </p>

                        <b-list-group>
                            <b-list-group-item>
                                <p>
                                    para aquellos que ya forman parte de la empresa
                                </p>
                                <div>
                                    <div @click="openModal('modal-talento-interno')" class="btn btn-primary">Aplicar
                                        como talento Interno
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item>
                                <p>
                                    para aquellos que previamente han registrado sus datos en la base de datos de las
                                    empresas
                                    asociadas a Helexium.
                                </p>
                                <div>
                                    <div @click="openModal('modal-identificacion-existente')"
                                        class="btn btn-primary">
                                        Aplicar con identificación
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>

                        <div class="d-flex justify-content-end mt-3">
                            <div class="btn btn-primary" @click="close()">
                                Omitir
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>

        <b-modal id="modal-talento-interno" class="modal-sq" size="lg" hide-footer hide-header>
            <template #default="{close}">
                <div class="px-3 py-2">
                    <div class="d-flex justify-content-between mb-3" style="width: 100%;">
                        <p class="mb-0 h4"><strong>Aplicar como talento interno</strong></p>
                        <div @click="close()" class="btn btn-danger btn-sm btn-pm">
                            <i class="fas fa-times"></i>
                        </div>
                    </div>
                    <p>
                        Si ya es un miembro de nuestra empresa, le invitamos a utilizar esta opción para explorar oportunidades internas o actualizar su perfil.
                    </p>
                    <BVForm :schema="[
                        {
                            name: 'numeroIdentidicacion',
                            label: 'Número de identificación',
                            type: 'text',
                            validations: 'required',
                            value: null,
                        }
                    ]" :callBackSubmit="aplicarTalentoInterno">
                        <template #text-submit>
                            Aplicar
                        </template>
                    </BVForm>
                </div>
            </template>

        </b-modal>

        <b-modal id="modal-identificacion-existente" class="modal-sq" size="lg" hide-footer hide-header>
            <template #default="{ close }">
                <div class="px-3 py-2">
                    <div class="d-flex justify-content-between mb-3" style="width: 100%;">
                        <p class="mb-0 h4"><strong>Aplicar con identificación existente</strong></p>
                        <div @click="close()" class="btn btn-danger btn-sm btn-pm">
                            <i class="fas fa-times"></i>
                        </div>
                    </div>
                    <p>
                        Si ha enviado previamente su solicitud a través de la base de datos de empresas afiliadas a Helexium, puede utilizar esta opción para acceder y actualizar su perfil.
                    </p>
                    <BVForm :schema="[
                        {
                            name: 'numeroIdentidicacion',
                            label: 'Número de identificación',
                            type: 'text',
                            validations: 'required',
                            value: null,
                        }
                    ]" :callBackSubmit="aplicarIdentificacionExistente">
                        <template #text-submit>
                            Aplicar
                        </template>
                    </BVForm>
                </div>
            </template>

        </b-modal>




    </div>
</template>

<style scoped>
.btn-pm {
    position: relative;
    min-width: 20px;
    height: 20px;
    text-align: center;
    padding: 2px 5px;
    margin: 0px 2px;
    border: none;
    border-radius: 5px;
}

.btn-pm i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>